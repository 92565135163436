/**
 * Enum of all CustomEvents types that clients can interchange.
 * These types anounce what the payload is related to and trigger actions.
 */
export var SocketCustomEventNames;
(function (SocketCustomEventNames) {
    /**
     * Announce that client has joined session
     */
    SocketCustomEventNames["announceJoin"] = "announceJoin";
    /**
     * Item of of model has been marked by a client
     */
    SocketCustomEventNames["mark"] = "mark";
    /**
     * Point in space has been marked by a client
     */
    SocketCustomEventNames["pointMark"] = "pointMark";
    /**
     * A machine model has been selected by a client (the host of the session)
     */
    SocketCustomEventNames["selectedMachine"] = "selectedMachine";
    /**
     * A client has broadcasted its camera position for others to sync to if they choose to do so
     */
    SocketCustomEventNames["cameraSync"] = "cameraSync";
})(SocketCustomEventNames || (SocketCustomEventNames = {}));
