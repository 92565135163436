/**
 * List of all available display modes of the application
 */
export var DisplayModes;
(function (DisplayModes) {
    /** Normal 3D graphics on traditional computer screen or phone etc. */
    DisplayModes["traditional"] = "traditional-display";
    /**
     * Augmented reality display.
     * Supported devices are Chrome on Android and others supporting WebXR AR mode.
     * See [babylon.js docs](https://doc.babylonjs.com/divingDeeper/webXR/webXRARFeatures#supported-devices) */
    DisplayModes["ar"] = "augmented-reality";
    /**
     * Virtual reality display.
     * Supported devices are for example the oculus browser on Oculus/Meta Quest devices
     * and others supporting WebXR VR mode.
     */
    DisplayModes["vr"] = "virtual-reality";
})(DisplayModes || (DisplayModes = {}));
