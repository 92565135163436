// import EventEmitter from 'events';
import { writable } from "svelte/store";
/**
 * Keep the currently active machine selection
 */
export class MachineSelectionHandler {
    constructor() {
        /**
         * Additional subscriptable by svelte of machineUrl
         */
        this.machineUrlStore = writable();
    }
    /**
     * Set the machineUrl to use. Sets it in traditional state keeping and in the subscriptable store.
     */
    set machineUrl(machineUrl) {
        this.internalMachineUrl = machineUrl;
        this.machineUrlStore.set(machineUrl);
    }
    get machineUrl() {
        return this.internalMachineUrl;
    }
}
