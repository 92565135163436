import * as Babylon from '@babylonjs/core';
import 'uuid';
import { MissingUniqueUrlKeyError } from "../declarations/exceptions/MissingUniqueUrlKeyError";
let debounceTimer;
const gameInspiredColors = [
    // copied from https://among-us.fandom.com/wiki/Colors#List_of_Colors
    '#b30a1f',
    '#132fd2',
    '#127f2d',
    '#ed53b9',
    '#ef7d0e',
    '#f6f659',
    // '#f6f659', is black so idk if smart to use
    // '#d5e0ef', is white so idk if smart to use
    '#6c31bc',
    '#72491e',
    '#39fedb',
    '#50ef3a',
    // '#6a2c3c', // maroon
    '#ecc0d3', // rose
    // '#fffebe', // banana
    // '#708496', // gray
    // '#928776', // tan
    // '#ec7578' // coral
];
/**
 * Class containing some static helper methods
 */
export class Utils {
    /**
     * Gets unique room config from url fragment
     * @returns uniqueroom config if fragment exists or throws exception
     */
    static getUniqueRooomConfigFromUrl() {
        let fragment = window.location.hash;
        if (fragment) {
            fragment = fragment.replace('#', '');
            const keyValue = fragment.split('=');
            if (keyValue.length !== 2) {
                throw new MissingUniqueUrlKeyError('No url hash provided');
            }
            if (keyValue[0] != 'id') {
                throw new MissingUniqueUrlKeyError('No access url fragment provided');
            }
            return {
                uuid: keyValue[1],
                link: window.location.href.split('#')[0] + '#id=' + keyValue[1]
            };
        }
        throw new MissingUniqueUrlKeyError('No url hash existing');
    }
    /** Generates a random color in hex format */
    static getRandomColor() {
        // from https://stackoverflow.com/a/1484514
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    /**
     * Stylises the screen shown while Babylon.JS loads the wanted model.
     * Applies some Pragmatic industries coporate identity design to this screen
     */
    static styliseBabylonLoadingScreen() {
        // from https://forum.babylonjs.com/t/change-the-standard-loading-screen/964/2
        // which linked https://www.babylonjs-playground.com/#5Y2GIC#2
        Babylon.DefaultLoadingScreen.prototype.displayLoadingUI = function () {
            if (this._loadingDiv) {
                // Do not add a loading screen if there is already one
                return;
            }
            this._loadingDiv = document.createElement("div");
            this._loadingDiv.id = "babylonjsLoadingDiv";
            this._loadingDiv.style.opacity = "0";
            this._loadingDiv.style.transition = "opacity 1.5s ease";
            this._loadingDiv.style.pointerEvents = "none";
            // Loading text
            this._loadingTextDiv = document.createElement("div");
            this._loadingTextDiv.style.position = "absolute";
            this._loadingTextDiv.style.left = "0";
            this._loadingTextDiv.style.top = "50%";
            this._loadingTextDiv.style.marginTop = "80px";
            this._loadingTextDiv.style.width = "100%";
            this._loadingTextDiv.style.height = "20px";
            this._loadingTextDiv.style.fontFamily = "Arial";
            this._loadingTextDiv.style.fontSize = "14px";
            this._loadingTextDiv.style.color = "white";
            this._loadingTextDiv.style.textAlign = "center";
            this._loadingTextDiv.innerHTML = "Loading";
            this._loadingDiv.appendChild(this._loadingTextDiv);
            //set the predefined text
            this._loadingTextDiv.innerHTML = this._loadingText;
            // Generating keyframes
            const style = document.createElement('style');
            style.type = 'text/css';
            // original
            // var keyFrames = "@-webkit-keyframes loadingAnimation { 0% { -webkit-transform: rotate(0deg);}\n 100% { -webkit-transform: rotate(360deg);}\n } @keyframes loadingAnimation { 0% { transform: rotate(0deg);}\n 100% { transform: rotate(360deg);}\n }";
            // original but multiline
            const keyFrames = `
      @-webkit-keyframes loadingAnimation {
        0% { -webkit-transform: rotate(0deg);}
        100% { -webkit-transform: rotate(360deg);}
      }
      @keyframes loadingAnimation {
        0% { transform: rotate(0deg);}
        100% { transform: rotate(360deg);}
      }`;
            // tried left to right keyframes from loadingAnimation
            // var keyFrames = `@-webkit-keyframes loadingAnimation {
            //   0% { left: 0;}
            //   50%{ left : 100%;}
            //   100%{ left: 0;}
            // }`;
            style.innerHTML = keyFrames;
            document.getElementsByTagName('head')[0].appendChild(style);
            // Loading img
            const imgBack = new Image();
            // changed image link and position
            imgBack.src = `./pi-assets/logo_default.svg`;
            imgBack.style.width = "25vw";
            imgBack.style.position = "absolute";
            imgBack.style.left = "37.5%";
            imgBack.style.top = "37.5%";
            imgBack.style.transform = "translate(-37.5%, -37.5%)";
            // imgBack.style.marginLeft = "-60px";
            // imgBack.style.marginTop = "-60px";
            // increased 2s to 5s to make way slower
            imgBack.style.animation = "loadingAnimation 5s infinite ease-in-out";
            imgBack.style.webkitAnimation = "loadingAnimation 5s infinite ease-in-out";
            // imgBack.style.transformOrigin = "50% 50%";
            // imgBack.style.webkitTransformOrigin = "50% 50%";
            this._loadingDiv.appendChild(imgBack);
            this._resizeLoadingUI();
            window.addEventListener("resize", this._resizeLoadingUI);
            // background image cool idea but loads too slow only loads in when almost gone
            // this._loadingDiv.style.backgroundImage = `url("${window.location.origin}/pi-assets/background_white.png")`;
            this._loadingDiv.style.backgroundColor = `#2c343a`;
            document.body.appendChild(this._loadingDiv);
            this._loadingDiv.style.opacity = "1";
        };
    }
    /**
     * Generic debounce helper
     * @param func Function to debounce
     * @param debounceTime Time in ms to debounce function to
     */
    static debounce(func, debounceTime) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(func, debounceTime);
    }
    /**
     * Find out if code is running in browser on a mobile device (with touch).
     */
    static isMobileDevice() {
        // thx to https://stackoverflow.com/a/3540295
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
}
