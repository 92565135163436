<script lang="ts">
  import { Toggle, ToggleSkeleton } from "carbon-components-svelte";
  import { DisplayModes } from "../declarations/types/webGLdisplayMode";
  
  import type { InteractionSyncHandler } from "../logic/InteractionSyncHandler";

  export let interactionSyncHandler: InteractionSyncHandler;
  const stateChanged = (event) => {
    console.log(event.detail);
    interactionSyncHandler.cameraSyncReceive = event.detail.value;
  }
</script>

{#if interactionSyncHandler && interactionSyncHandler.sceneHandler.displayMode === DisplayModes.traditional}
    <Toggle class="toggle" bind:toggled={interactionSyncHandler.cameraSyncReceive} labelText="Camera Sync Receive" on:change={stateChanged}></Toggle>
{:else}
    <ToggleSkeleton class="toggle skeleton" labelText="Camera Sync Receive"></ToggleSkeleton>
{/if}


<style>
  :global(.toggle) {
    flex: none !important;
    color: #333;
    background-color: #f4f4f4;
    border: 2px solid #333;
    padding: 7px;
  }

  :global(.toggle.skeleton) {
    visibility: hidden;
  }
</style>