<script>
  // modified https://github.com/carbon-design-system/carbon-components-svelte/blob/master/src/CopyButton/CopyButton.svelte to use my own icon

  /** Set the title and ARIA label for the copy button */
  export let iconDescription = "Copy to clipboard";

  /**
   * Specify the text to copy
   * @type {string}
   */
  export let text = undefined;

  /**
   * Override the default copy behavior of using the navigator.clipboard.writeText API to copy text
   * @type {(text: string) => void}
   */
  export let copy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (e) {
      console.log(e);
    }
  };

  import Copy from "./Copy/Copy.svelte";
  import Share16 from "carbon-icons-svelte/lib/Share16/Share16.svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
</script>

<Copy
  class="bx--copy-btn"
  aria-label="{iconDescription}"
  title="{iconDescription}"
  {...$$restProps}
  on:click
  on:click="{() => {
    if (text !== undefined) {
      copy(text);
      dispatch('copy');
    }
  }}"
  on:animationend
>
  <Share16 class="bx--snippet__icon" />
</Copy>