import { DJANGO_API_HOST } from "../declarations/constants";
/**
 * Class to handle all requests to REST-API. Retrieving machine models etc.
 */
export class ApiHandler {
    constructor() {
        /** REST-Api path to retrieve all existing models from */
        this.modelsListPath = '/imi/machine-models/?format=json';
        /**
         * Cache to store model defintions in
         */
        this.modelDefinitionsCache = [];
    }
    /**
     * Get full absolute URL for REST-Api on basis of API path.
     * @param path API path
     * @returns Absolute url for REST-Api
     */
    getApiUrl(path) {
        return `https://${DJANGO_API_HOST}${path}`;
        // for development use this
        // could be automated with .env starting script switch
        // return `http://${DJANGO_API_DEV_HOST}${path}`;
    }
    /**
     * Retrieves models.json and converts them to options for this dropdown
     */
    async getModelsAsOptions() {
        const data = await fetch(this.getApiUrl(this.modelsListPath));
        const json = await data.json();
        this.modelDefinitionsCache = json;
        return json.map(definition => {
            return {
                id: definition.file,
                text: definition.name
            };
        });
    }
    async getModelDefinitionByFileName(fileName) {
        if (!this.modelDefinitionsCache) {
            const data = await fetch(this.getApiUrl(this.modelsListPath));
            this.modelDefinitionsCache = await data.json();
        }
        return this.modelDefinitionsCache.find(definition => definition.file === fileName);
    }
}
