<script lang="ts">
	import { onMount } from 'svelte';

	import { SceneHandler } from './logic/SceneHandler';

	import { ApiHandler } from './logic/ApiHandler';
	import { CommunicationHandler } from './logic/CommunicationHandler';
	import { InteractionSyncHandler } from './logic/InteractionSyncHandler';

	import Sharing from './components/Sharing.svelte';
	import MachineSelection from './components/MachineSelection.svelte';
	import CameraSyncToggle from './components/CameraSyncToggle.svelte';
	
	import { Utils } from './logic/utils';
	import { MachineSelectionHandler } from './logic/MachineSelectionHandler';

	// carbon components styling css
	// for themes see https://github.com/carbon-design-system/carbon-components-svelte#css-stylesheet
	import "carbon-components-svelte/css/white.css";


	/** API handler for api requests to backend */
	const apiHandler = new ApiHandler();

	let canvasElement: HTMLCanvasElement;
	let sceneHandler: SceneHandler;
	let interactionSyncHandler: InteractionSyncHandler;

	// stores the activate machine
	let machineSelectionHandler = new MachineSelectionHandler();

	/**
	 * CommunicationHandler and flag to set when it's ready to use
	 */
	let communicationHandler: CommunicationHandler;


	let handlersReady: boolean;


	/**
	 * Initializes communication for app
	 */
	const initialiseCommunication = async () => {
		try {
			const retrievedRoomConfig = Utils.getUniqueRooomConfigFromUrl();
			if (retrievedRoomConfig) {
				console.log('Will join room: ' + retrievedRoomConfig.uuid);
				communicationHandler = new CommunicationHandler(machineSelectionHandler, retrievedRoomConfig);
			}
		} catch(e) {
			console.log('Did not join room but acts as host because: ' + e.message);
			communicationHandler = new CommunicationHandler(machineSelectionHandler);
		}

		await communicationHandler.initalize();
		// comms can now be considered ready
		handlersReady = true;

		// subscribe to changes of machineUrl and re initialize everything if needed
		machineSelectionHandler.machineUrlStore.subscribe((newId) => {
			selectMachine(newId);
		});

		// initialse scene with default we have right now like
		// selectMachine('L1_Babylon.babylon');
	};

	const selectMachine  = async (machineModelFilename: string) => {
		if (!machineModelFilename) {
			console.warn('Tried to initialize app without machine model filename');
			return;
		}
		// update handler first
		machineSelectionHandler.machineUrl = machineModelFilename;

		// then re setup many handlers

		// completely replaces sceneHandler and interactionSyncHandler everytime it's called
		// to make sure state of them is empty
		sceneHandler = new SceneHandler(canvasElement, machineSelectionHandler, apiHandler);
		await sceneHandler.setup();

		interactionSyncHandler = new InteractionSyncHandler(sceneHandler, communicationHandler);
		await interactionSyncHandler.setup();

		// communicationHandler just gets changed listener in it
		communicationHandler.setupMessageListener(interactionSyncHandler);
		communicationHandler.broadcastMachineSelection();

		// shortcut to download current scene and debug in babylonjs debugger
		// document.addEventListener('keyup', (event: KeyboardEvent) => {
		// 	if (event.key === 'p') {
		// 		sceneHandler.downloadScene();
		// 	}
		// });

	}

	// initialize and re initialize
	onMount(initialiseCommunication);
	window.onpopstate = (ev) => {
		initialiseCommunication()
	};
</script>

<div id="topBar" style="--client-color: {communicationHandler?.clientColor || '#F00'}">	
	<MachineSelection {handlersReady} {communicationHandler} {machineSelectionHandler} {apiHandler}></MachineSelection>
	<CameraSyncToggle {interactionSyncHandler}></CameraSyncToggle>
	<Sharing {handlersReady} {communicationHandler}></Sharing>
</div>

<div id="container" >
	<canvas
		bind:this={canvasElement}
		touch-action="none"
	></canvas>
	<!-- 	
		possible bindings if I wanted full sync	
		on:click=""
		on:keyup=""
		on:mousemove=""
		on:mousewheel="" 
	-->

</div>

<div id="licenseNote">
	<a href="./assets/licenses.csv">Licenses</a>
</div>


<style lang="scss">
	// sass integration tutorial https://daveceddia.com/svelte-with-sass-in-vscode/
	#container {
		height: 100vh;
		overflow: hidden;
		z-index: 1;
		background-color: #2c343a;
	}

	#topBar {
		z-index: 2;
		position: absolute;
		left: 0px;
		right: 0px;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 10px;

		background-color: var(--client-color);
	}

	div#licenseNote {
		position: absolute;
		bottom: 5px;
		right: 5px;
		text-align: right;
		padding: 3px 5px 5px 5px;
		border-radius: 5px;

		background-color: #fff;
		color: #333;

		&>a {
			color: inherit;
			text-decoration: none;
		}
	}
</style>