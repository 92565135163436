import * as Babylon from '@babylonjs/core';
import { Vector3 } from '@babylonjs/core';
// need to export @babylonjs/loaders so it does not get tree shaked in case xr is used
// xr depends on babylonjs loader existing
import '@babylonjs/loaders';
import { DisplayModes } from '../declarations/types/webGLdisplayMode';
import { Utils } from './utils';
/**
 * Lowest level self implemented handler for the scene.
 * Sets scene up, provides some information to retrieve about scene or starts downloads and conversion to XR modes.
 */
export class SceneHandler {
    constructor(canvasElement, machineSelectionHandler, apiHandler) {
        this.canvasElement = canvasElement;
        this.machineSelectionHandler = machineSelectionHandler;
        this.apiHandler = apiHandler;
        Utils.styliseBabylonLoadingScreen();
        // load the 3D engine
        // this.engine = new Babylon.Engine(this.canvasElement, true, { stencil: true });
        this.engine = new Babylon.Engine(this.canvasElement, false, { stencil: true });
    }
    /**
     * Async setup engine
     * @returns Promise which resolves when setup is finished
     */
    setup() {
        return new Promise((resolve) => {
            // BABYLON.SceneLoader.Load("model/", "L1_Babylon.gltf", engine, function (scene) {
            Babylon.SceneLoader.LoadAsync("", this.machineSelectionHandler.machineUrl, this.engine).then(async (scene) => {
                /**
                 * AR and VR detection and handling.
                 * Modify scene to xr scene only if supported by device.
                 * If is supported modify scene to allow for that.
                */
                let displayMode = DisplayModes.traditional;
                if (await Babylon.WebXRSessionManager.IsSessionSupportedAsync('immersive-ar')) {
                    // first check for AR because android chrome supports both
                    // but probably want AR there?
                    displayMode = DisplayModes.ar;
                }
                else if (await Babylon.WebXRSessionManager.IsSessionSupportedAsync('immersive-vr')) {
                    displayMode = DisplayModes.vr;
                }
                this._displayMode = displayMode;
                console.log(`Display Mode is ${displayMode}`);
                if (displayMode === DisplayModes.ar || displayMode === DisplayModes.vr) {
                    scene = await this.convertSceneToXr(scene, displayMode);
                }
                this.scene = scene;
                scene.executeWhenReady(() => {
                    this.setupScene(this.canvasElement);
                    resolve();
                });
                // the canvas/window resize event handler
                window.addEventListener('resize', () => {
                    this.engine.resize();
                });
            });
        });
    }
    getScene() {
        return this.scene;
    }
    /**
     * Whether application is running as traditional 3D rendering, virtual reality or augmented reality
     */
    get displayMode() {
        return this._displayMode;
    }
    /**
     * Returns height of user in meters if XR supported and running.
     * Falls back to 1.6m
     */
    get userHeight() {
        return this._userHeight;
    }
    /** Create a download prompt to download current scene as .babylon file */
    downloadScene() {
        var objectUrl;
        function doDownload(filename, scene) {
            if (objectUrl) {
                window.URL.revokeObjectURL(objectUrl);
            }
            var serializedScene = Babylon.SceneSerializer.Serialize(scene);
            var strScene = JSON.stringify(serializedScene);
            if (filename.toLowerCase().lastIndexOf(".babylon") !== filename.length - 8 || filename.length < 9) {
                filename += ".babylon";
            }
            var blob = new Blob([strScene], { type: "octet/stream" });
            // turn blob into an object URL; saved as a member, so can be cleaned out later
            objectUrl = (window.webkitURL || window.URL).createObjectURL(blob);
            var link = window.document.createElement("a");
            link.href = objectUrl;
            link.download = filename;
            var click = document.createEvent("MouseEvents");
            click.initEvent("click", true, false);
            link.dispatchEvent(click);
        }
        doDownload('updated.babylon', this.scene);
    }
    /**
     * Converts existing scene to XR scene by replacing cameras, lighting and so on.
     * @returns Modified now XR scene
     */
    async convertSceneToXr(scene, xrType) {
        // finally found out how to do it with this playground https://playground.babylonjs.com/#9K3MRA#1
        // and by making sure all old cameras were disposed of
        console.log(`Converting scene to XR ready scene for ${xrType}`);
        /**** Clean up unwanted elements from imported scene to convert them ****/
        while (scene.cameras.length > 0) {
            // remove all already existing cameras
            scene.cameras[0].dispose();
        }
        const hdrSkyBox = scene.getMeshByName('hdrSkyBox');
        const oldPlane = scene.getMeshByName('Plane');
        hdrSkyBox === null || hdrSkyBox === void 0 ? void 0 : hdrSkyBox.dispose();
        oldPlane === null || oldPlane === void 0 ? void 0 : oldPlane.dispose();
        /************************************************************************/
        const currentMachine = await this.apiHandler.getModelDefinitionByFileName(this.machineSelectionHandler.machineUrl);
        const machine = scene.getTransformNodeByName(currentMachine.machine_node_name);
        const camera = new Babylon.FreeCamera("camera1", new Babylon.Vector3(0, 5, -10), scene);
        camera.setTarget(Babylon.Vector3.Zero());
        camera.attachControl(this.canvasElement, true);
        const light = new Babylon.HemisphericLight("light", new Babylon.Vector3(0, 1, 0), scene);
        light.intensity = 0.7;
        const environment = scene.createDefaultEnvironment();
        let xrHelper;
        if (xrType === DisplayModes.vr) {
            xrHelper = await scene.createDefaultXRExperienceAsync({
                floorMeshes: [environment.ground]
            });
        }
        else if (xrType === DisplayModes.ar) {
            // see https://doc.babylonjs.com/divingDeeper/webXR/webXRARFeatures for more nice features
            xrHelper = await scene.createDefaultXRExperienceAsync({
                // make auto detect which is possible
                uiOptions: {
                    sessionMode: "immersive-ar",
                }
            });
            // removed background skybox and plane in AR for transparency
            const xrBackgroundRemover = xrHelper.baseExperience.featuresManager.enableFeature(Babylon.WebXRBackgroundRemover);
        }
        const xrCamera = xrHelper.baseExperience.camera;
        // helpful for debugging
        // (window as any).xrCamera = xrCamera;
        // (window as any).BABYLON = Babylon;
        // via vertical field of view and machine height get focal length?
        xrCamera.position = new Vector3(0, 0, 3.5);
        // xrCamera.rotation = new Vector3(3.14,0,3.14);
        // position x,y,z is center of node so -> height = y*2
        const machineMeshHeight = machine.position.y * 2;
        const maschineActualHeight = currentMachine.real_height_in_meters;
        const machineScaleFactor = maschineActualHeight / machineMeshHeight;
        machine.scalingDeterminant = machineScaleFactor;
        machine.position = new Vector3(0, machine.position.y * machineScaleFactor, 0);
        // a bit hard not sure if want to do that
        // xrHelper.baseExperience.enterXRAsync();
        xrHelper.baseExperience.camera.setEnabled(true); // just to be sure
        xrHelper.enterExitUI.activeButtonChangedObservable.add((event) => {
            // get user height (y coordinate of camera in space); fallback to 1.6m because that's value in emulator extension
            // falllback to kinda reasonable height
            this._userHeight = xrCamera.realWorldHeight || 1.75;
            // alert(this._userHeight);
        });
        return scene;
    }
    /**
     * Sets up the scene itself e.g. create an active camera if that is missing.
     * @param canvasElement HTMLCanvasElement containing scene
     */
    setupScene(canvasElement) {
        // Now the Model
        if (!this.scene.activeCamera) {
            this.scene.createDefaultCameraOrLight(true, true, true);
            this.scene.setActiveCameraByID(this.scene.cameras[0].id);
        }
        this.scene.activeCamera.attachControl(canvasElement, true);
        // reduce rotating sensitivity on mobile devices
        if (Utils.isMobileDevice()) {
            this.scene.activeCamera.angularSensibilityX = 2000;
            this.scene.activeCamera.angularSensibilityY = 2000;
            this.scene.activeCamera.wheelPrecision = 0.5;
        }
        // run the render loop
        this.engine.runRenderLoop(() => {
            this.scene.render();
        });
        let t = 0;
        const transform = (obj) => {
            return new Babylon.Vector3(obj.x, obj.y, obj.z);
        };
        const visit = (node) => {
            // console.log("Visit " + node.id)
            let result = {};
            result[node.id] = node;
            for (let child of node.getChildren()) {
                result = new Map([result, visit(child)]);
            }
            return result;
        };
        // Discover Nodes
        let nodes = this.scene.rootNodes.reduce((map, rootNode) => {
            return new Map([map, visit(rootNode)]);
        }, {});
    }
}
