/**
 * Exception to throw when sharing url hash key was missing to identify session.
 * This means the url was malformed and it was impossible to parse the correct query parameter.
 */
export class MissingUniqueUrlKeyError extends Error {
    constructor(msg) {
        super(msg);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, MissingUniqueUrlKeyError.prototype);
    }
}
