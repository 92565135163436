import * as Babylon from '@babylonjs/core';
import { v4 as uuidv4 } from 'uuid';
import { InvalidLoadedModelSceneError } from '../declarations/exceptions/InvalidLoadedModelSceneError';
import { DisplayModes } from '../declarations/types/webGLdisplayMode';
/**
 * Class to handle interaction with the scene e.g. clicking items/points or hightlighting these items/points.
 * So basically event handlers for user interaction events happening in the scene and scene modification methods.
 */
export class InteractionSyncHandler {
    constructor(sceneHandler, communicationHandler) {
        this.sceneHandler = sceneHandler;
        this.communicationHandler = communicationHandler;
        /** Keep record of all meshes which are marked. Key is meshId and value is color in hex */
        this.markedItems = {};
        /** Toggle if client should follow camera sync broadcasts or keep its camera position */
        this.enableCameraSyncReceive = true;
    }
    /**
     * Async setup of interaction syncing.
     * Is done after instantiating because of async nature but before first use.
     * Sets up listener for interaction on scene
     */
    async setup() {
        this.scene = this.sceneHandler.getScene();
        if (this.sceneHandler.displayMode !== DisplayModes.traditional) {
            console.warn('Broadcast of ar/vr cameras not implemented yet');
            this.enableCameraSyncReceive = false;
        }
        // setup highlight layer for later use
        // glow layer did not work and highlight might be even better
        // so highlight for now
        // this.glowLayer = new Babylon.GlowLayer("glow", this.scene);
        this.highlightLayer = new Babylon.HighlightLayer("hl1", this.scene);
        // setup pointer events handlers
        // https://doc.babylonjs.com/divingDeeper/mesh/interactions/picking_collisions
        this.scene.onPointerObservable.add((pointerInfo) => {
            var _a, _b, _c;
            switch (pointerInfo.type) {
                // case Babylon.PointerEventTypes.POINTERDOWN:
                //     console.log("POINTER DOWN");
                //     break;
                // case Babylon.PointerEventTypes.POINTERUP:
                //     console.log("POINTER UP");
                //     break;
                // case Babylon.PointerEventTypes.POINTERMOVE:
                //     console.log("POINTER MOVE");
                //     break;
                // case Babylon.PointerEventTypes.POINTERWHEEL:
                //     console.log("POINTER WHEEL");
                //     break;
                case Babylon.PointerEventTypes.POINTERPICK:
                    // console.log("POINTER PICK");
                    break;
                case Babylon.PointerEventTypes.POINTERTAP:
                    // console.log("POINTER TAP");
                    // console.log(pointerInfo)
                    const pickedMeshId = (_b = (_a = pointerInfo === null || pointerInfo === void 0 ? void 0 : pointerInfo.pickInfo) === null || _a === void 0 ? void 0 : _a.pickedMesh) === null || _b === void 0 ? void 0 : _b.id;
                    if (pickedMeshId) {
                        console.log('You picked this meshId: ' + pickedMeshId);
                        this.communicationHandler.markItem(pickedMeshId);
                    }
                    // also communicate pointer tap coordinated to server
                    if ((_c = pointerInfo === null || pointerInfo === void 0 ? void 0 : pointerInfo.pickInfo) === null || _c === void 0 ? void 0 : _c.pickedPoint) {
                        console.log('You picked this point: ' + pointerInfo.pickInfo.pickedPoint);
                        this.communicationHandler.markPoint(pointerInfo.pickInfo.pickedPoint);
                    }
                    // also always broadcast camera position at every interaction
                    this.communicationHandler.broadcastCameraPosition();
                    break;
                case Babylon.PointerEventTypes.POINTERDOUBLETAP:
                    // console.log("POINTER DOUBLE-TAP");
                    break;
            }
        });
    }
    /**
     * Let a mesh glow with a given color by adding it to the highlight layer.
     * @param meshId Id of mesh to add to highlight layer
     * @param color color in hex format to highlight mesh with
     */
    letMeshGlow(meshId, color) {
        const mesh = this.scene.getMeshByID(meshId);
        const babylonColor = Babylon.Color3.FromHexString(color);
        if (!mesh) {
            console.warn('Mesh to glow up not found!');
            return;
        }
        // Algorithm
        // if no color previously color it
        // if had other color re color it
        // if had same color stop highlighting
        if (!this.markedItems[meshId]) {
            // this.glowLayer.removeIncludedOnlyMesh(mesh as Babylon.Mesh)
            this.highlightLayer.addMesh(mesh, babylonColor);
            this.markedItems[meshId] = color;
        }
        else if (this.markedItems[meshId] !== color) {
            this.highlightLayer.removeMesh(mesh);
            this.highlightLayer.addMesh(mesh, babylonColor);
            this.markedItems[meshId] = color;
        }
        else {
            // this.glowLayer.addIncludedOnlyMesh(mesh as Babylon.Mesh)
            this.highlightLayer.removeMesh(mesh);
            delete this.markedItems[meshId];
        }
    }
    /**
     * Get ArcRotateCamera position as sync format data.
     * Modify if other camera types are added.
     */
    get cameraPosition() {
        const cam = this.scene.activeCamera;
        // https://doc.babylonjs.com/divingDeeper/webXR/webXRCamera#updating-from-a-non-vr-camera
        // const cam = (this.scene.activeCamera as Babylon.WebXRCamera);
        if (this.sceneHandler.displayMode !== DisplayModes.traditional) {
            console.warn('Broadcast of ar/vr cameras not implemented yet');
            return;
        }
        if (!cam || !cam.target || !cam.radius) {
            throw new InvalidLoadedModelSceneError('No active arc rotate camera in scene!');
        }
        return {
            alpha: cam.alpha,
            beta: cam.beta,
            radius: cam.radius,
            target: cam.getTarget().asArray(),
            displayMode: this.sceneHandler.displayMode
        };
    }
    /**
     * Construct tag to re identify marking spheres with color included
     */
    getTagForSphere(color) {
        return `pointPickSphere-${color}`;
    }
    /**
     * Create a pulsating spere at given position with given color to mark this point in space.
     * Is autmatically removed again after 2 seconds.
     * @param point Point in space to mark as vector3
     * @param color Color mark point with in hex format
     */
    pulseSphereAtPoint(point, color) {
        const sphereColorTag = this.getTagForSphere(color);
        const sphereName = `${sphereColorTag}-${uuidv4()}`;
        const sphereMaterialName = `${sphereColorTag}-mat`;
        const sphereColor = Babylon.Color3.FromHexString(color);
        // make sure again to dispose of all existing spheres in scene with same color as new one
        // const existingSpheresInScene = this.scene.getMeshesByTags(sphereColorTag);
        // if (existingSpheresInScene && existingSpheresInScene.length > 0) {
        //   existingSpheresInScene.forEach(sphere => sphere.dispose());
        // }
        const size = this.sceneHandler.userHeight ? (this.sceneHandler.userHeight / 10) : 6;
        const sphere = Babylon.MeshBuilder.CreateSphere(sphereName, { diameter: size }, this.scene);
        sphere.position = point;
        sphere.material = new Babylon.StandardMaterial(sphereMaterialName, this.scene);
        sphere.material.backFaceCulling = true;
        // spheres irrelevant to raycasting
        // should go through them and only pick object behin
        sphere.isPickable = false;
        // tag spheres to find all existing ones again later and dispose
        Babylon.Tags.AddTagsTo(sphere, sphereColorTag);
        // also add to highlight layer because we want to
        // not actually higlight but for the emissive color not overwritten by highlight layer
        this.highlightLayer.addMesh(sphere, sphereColor);
        this.highlightLayer.addExcludedMesh(sphere);
        // seems kinda hacky but works
        // (sphere.material as any).diffuseColor = sphereColor;
        sphere.material.emissiveColor = sphereColor;
        // (sphere.material as any).specularColor = sphereColor;
        sphere.material.alpha = 0.7;
        const animation = new Babylon.Animation('pulse', 'scaling', 30, Babylon.Animation.ANIMATIONTYPE_VECTOR3, Babylon.Animation.ANIMATIONLOOPMODE_CONSTANT);
        const keys = [];
        keys.push({
            frame: 0,
            value: new Babylon.Vector3(1, 1, 1)
        });
        keys.push({
            frame: 30,
            value: new Babylon.Vector3(1.5, 1.5, 1.5)
        });
        keys.push({
            frame: 60,
            value: new Babylon.Vector3(1, 1, 1)
        });
        animation.setKeys(keys);
        sphere.animations.push(animation);
        this.scene.beginAnimation(sphere, 0, 60, true);
        setTimeout(() => {
            // if sphere still exists after 2s of animation then dispose of it
            sphere === null || sphere === void 0 ? void 0 : sphere.dispose(false, true);
        }, 2000);
    }
    /**
     * Set ArcRotateCamera position to given sync format data.
     * Modify if other camera types are added.
     */
    set cameraPosition(cameraSyncFormat) {
        if (!this.enableCameraSyncReceive) {
            console.log('Not changing camera. Feature disabled');
            return;
        }
        if (this.sceneHandler.displayMode !== DisplayModes.traditional) {
            console.warn('Broadcast of ar/vr cameras not implemented yet');
            return;
        }
        const cam = this.scene.activeCamera;
        if (!cam || !cam.target || !cam.radius) {
            throw new InvalidLoadedModelSceneError('No active arc rotate camera in scene!');
        }
        cam.alpha = cameraSyncFormat.alpha;
        cam.beta = cameraSyncFormat.beta;
        cam.radius = cameraSyncFormat.radius;
        cam.setTarget(new Babylon.Vector3(...cameraSyncFormat.target));
    }
    /**
     * Switch camera sync receive feature on or off.
     * @param enable If true will move camera when receives command, else not
     */
    set cameraSyncReceive(enable) {
        this.enableCameraSyncReceive = enable;
    }
    /**
     * Get camera sync receive feature status.
     */
    get cameraSyncReceive() {
        return this.enableCameraSyncReceive;
    }
}
