<script lang="ts">
	import type { CommunicationHandler } from "../logic/CommunicationHandler";

	import CopyButton from "../lib/modifiedCarbonComponents/CopyButton.svelte";

	export let handlersReady: boolean;
	export let communicationHandler: CommunicationHandler;

	const shareUrl = (text) => {
		if (navigator.share) {
			// native share on mobile devices in https context
			navigator.share({
				url: communicationHandler.roomConfig.link,
			}).then(r => console.log('share invoked')).catch(e => console.warn(e));
		}
		else {
			// fallback copy to clipboard
			navigator.clipboard.writeText(communicationHandler.roomConfig.link);
		}
	}
</script>

{#if handlersReady && communicationHandler?.connected}
	<input id="link" readonly bind:value={communicationHandler.roomConfig.link} />
	<CopyButton text={communicationHandler.roomConfig.link} copy={shareUrl} />
{:else}
	<input id="link" readonly value="Connection to server missing" />
{/if}


<style>
	#link {
		color: #333;
		background-color: #f4f4f4;
		border: 2px solid #333;
		padding: 10px;

		display: flex;
		flex-grow: 0.85;
		font-size: 1.2em;

		/* to not collide with camera sync toggle */
		margin-top: 5px;
	}
</style>
