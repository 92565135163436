<script lang="ts">
  import { Dropdown } from "carbon-components-svelte";

  import type { ApiHandler } from "../logic/ApiHandler";
  import type { MachineSelectionHandler } from "../logic/MachineSelectionHandler";
	import type { CommunicationHandler } from './../logic/CommunicationHandler';

  export let apiHandler: ApiHandler;
  export let handlersReady: boolean;
  export let communicationHandler: CommunicationHandler;
  export let machineSelectionHandler: MachineSelectionHandler;

  const machineChanged = (event) => {
    machineSelectionHandler.machineUrl = event.detail.selectedId;
  }
</script>

{#await apiHandler.getModelsAsOptions()}
  <p>Loading</p>
{:then dropdownOptions}
  <Dropdown class="machineSelection"
    hideLabel
    disabled={handlersReady && !communicationHandler.isHost}
    on:select="{machineChanged}"
    titleText="Machine Type"
    label="Machine Type"
    items={dropdownOptions}
  />
{:catch error}
	<p class="error-msg">{error.message}</p>
{/await}


<style lang="scss">
  p.error-msg {
    color: red;
    padding: 10px;
    background-color: #f4f4f4;
    border: 2px solid #333;
    padding: 10px;
    margin-top: 5px;
  }

</style>