import { v4 as uuidv4 } from 'uuid';
import { DJANGO_WS_HOST } from "../declarations/constants";
import { SocketCustomEventNames } from "../declarations/types/socketMessages";
import { Utils } from "./utils";
import { Vector3 } from '@babylonjs/core';
// exported for docs but not could be private otherwise
export var CommunicationRoles;
(function (CommunicationRoles) {
    CommunicationRoles["Host"] = "host";
    CommunicationRoles["Member"] = "member";
})(CommunicationRoles || (CommunicationRoles = {}));
/** Class to handle all WebSocket communications (lowest level for websocket based interactions). */
export class CommunicationHandler {
    /**
     * Instantiate class with machineSelectionHandler and optionally (if member not host) with an existing roomConfig
     * @param roomConfig Optional room config if client is a member not host. If client is host the roomConfig will be generated
     */
    constructor(machineSelectionHandler, roomConfig) {
        this.machineSelectionHandler = machineSelectionHandler;
        /** Color of current client instantiated and randomly decided on start */
        this.clientColorStore = Utils.getRandomColor();
        /** Unique client id to identify client and its messages */
        this.clientId = uuidv4();
        if (roomConfig) {
            // assign input room config
            this.roomConfig = roomConfig;
        }
    }
    /**
     * Initialize communication handler with listeners and all the other stuff.
     * Is done async after instantiating but before first use.
     */
    initalize() {
        return new Promise((resolve) => {
            // setup rooms first
            if (this.roomConfig) {
                // is non host and will join given host
                this.connectAsMember(this.roomConfig.uuid);
            }
            else {
                // is host and will create room config for others to join
                this.connectAsHost();
            }
            // resolve after connection succesffully established
            this.socket.onopen = () => {
                console.log('Connected to: ' + this.currentRoom);
                // setup listeners initially without pick handler
                this.setupMessageListener();
                // announcement message that we connected
                const payload = {
                    eventType: SocketCustomEventNames.announceJoin,
                    data: this.role,
                    senderClientId: this.clientId
                };
                this.socket.send(JSON.stringify(payload));
                resolve();
            };
            this.socket.onclose = (e) => {
                console.error('Disconnected from: ' + this.currentRoom);
                console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
                setTimeout(function () {
                    this.initalize();
                }, 1000);
            };
            this.socket.onerror = (err) => {
                console.error('Socket encountered error: ', err, 'Closing socket');
                this.socket.close();
            };
        });
    }
    /** Find out if websocket connection is still open and ready */
    get connected() {
        var _a;
        return ((_a = this.socket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN;
    }
    /** Retrieve color for this client in hex format */
    get clientColor() {
        return this.clientColorStore;
    }
    /** Find out if client is the host of the room */
    get isHost() {
        return this.role === CommunicationRoles.Host;
    }
    /** Get full config of current room with uuid and link */
    get currentRoomConfig() {
        return this.currentRoom ? {
            uuid: this.currentRoom,
            link: window.location.href.split('#')[0] + '#id=' + this.currentRoom
        } : null;
    }
    /**
     * Sets up onmessage listener for websocket connection with given interactionSyncHandler.
     * Is called multiple times with changed interactionSyncHandler parameters
     * @param interactionSyncHandler
     */
    setupMessageListener(interactionSyncHandler) {
        // inject interactionSyncHandler via this method
        if (interactionSyncHandler) {
            this.interactionSyncHandler = interactionSyncHandler;
        }
        /**
         * All of the message handling has to happen in here with native js websocket object
         */
        this.socket.onmessage = (ev) => {
            const message = JSON.parse(ev.data);
            /*
              Mark points and meshes
            */
            if (interactionSyncHandler) {
                if (message.eventType === SocketCustomEventNames.mark) {
                    const markData = message;
                    console.log('Request to mark: ' + JSON.stringify(markData));
                    interactionSyncHandler.letMeshGlow(markData.data.item, markData.data.color);
                }
                else if (message.eventType === SocketCustomEventNames.pointMark) {
                    const pointData = message.data;
                    console.log('Request to mark point: ' + JSON.stringify(pointData));
                    this.interactionSyncHandler.pulseSphereAtPoint(new Vector3(...pointData.point), pointData.color);
                }
            }
            /*
              Machine selection listener handling according to role
            */
            if (this.role === CommunicationRoles.Host) {
                // host sends current machine to newly joined members
                if (message.eventType === SocketCustomEventNames.announceJoin) {
                    console.log('New member joined your room: ' + this.roomConfig.uuid);
                    this.broadcastMachineSelection();
                }
            }
            else {
                // members receive machine selection after joining
                if (message.eventType === SocketCustomEventNames.selectedMachine) {
                    console.log('Got pre selected machine from host: ' + message.data);
                    this.machineSelectionHandler.machineUrl = message.data;
                }
            }
            /**
             * Camera Sync listener
             */
            if (message.eventType === SocketCustomEventNames.cameraSync && message.senderClientId !== this.clientId) {
                const position = message.data;
                console.log('Request to change camera to: ' + JSON.stringify(position));
                this.interactionSyncHandler.cameraPosition = position;
            }
        };
    }
    /**
     * Connect to websocket room with given id
     * @param uuid Id of room to join
     */
    connectAsMember(uuid) {
        this.socket = new WebSocket(`wss://${DJANGO_WS_HOST}/ws/imi/${uuid}/`);
        this.role = CommunicationRoles.Member;
        this.currentRoom = uuid;
    }
    /**
     * Connect to websocket room and act as room host
     */
    connectAsHost() {
        this.role = CommunicationRoles.Host;
        this.roomConfig = this.createUniqueRoomConfig();
        this.currentRoom = this.roomConfig.uuid;
        this.socket = new WebSocket(`wss://${DJANGO_WS_HOST}/ws/imi/${this.currentRoom}/`);
    }
    broadcastMachineSelection() {
        if (this.role !== CommunicationRoles.Host) {
            console.log('Am not a host so should not broadcast a machine change!');
        }
        console.log('Will tell the members which machine is selected');
        const payload = {
            eventType: SocketCustomEventNames.selectedMachine,
            data: this.machineSelectionHandler.machineUrl,
            senderClientId: this.clientId
        };
        this.socket.send(JSON.stringify(payload));
    }
    /**
     * Camera position change handler to broadcast to clients.
     */
    broadcastCameraPosition() {
        const payload = {
            eventType: SocketCustomEventNames.cameraSync,
            data: this.interactionSyncHandler.cameraPosition,
            senderClientId: this.clientId
        };
        this.socket.send(JSON.stringify(payload));
    }
    /**
     * Creates an unique url
     */
    createUniqueRoomConfig() {
        // with dash does not work so replaceAll away to connect
        const uuid = uuidv4().replaceAll('-', '');
        return {
            uuid: uuid,
            link: window.location.href.split('#')[0] + '#id=' + uuid
        };
    }
    /**
     * Mark an item in the scene. Marks it locally in the display and sends message to mark for other clients to room.
     * @param itemId Id of item to mark (id of scene mesh)
     */
    markItem(itemId) {
        if (!this.connected) {
            console.error('Cannot mark item, server connection is broken. Should automatically try to reconnect');
        }
        const data = {
            color: this.clientColorStore,
            item: itemId
        };
        const payload = {
            eventType: SocketCustomEventNames.mark,
            data: data,
            senderClientId: this.clientId
        };
        this.socket.send(JSON.stringify(payload));
    }
    /**
     * Mark a point in space in the scene. Marks it locally in the display and sends message to mark for other clients to room.
     * @param point Point in space to mark
     */
    markPoint(point) {
        if (!this.connected) {
            console.error('Cannot mark item, server connection is broken. Should automatically try to reconnect');
        }
        const data = {
            color: this.clientColorStore,
            point: point.asArray()
        };
        const payload = {
            eventType: SocketCustomEventNames.pointMark,
            data: data,
            senderClientId: this.clientId
        };
        this.socket.send(JSON.stringify(payload));
    }
}
