/**
 * Exception to throw when loaded scene misses certain nodes to process requested action.
 * This probably means the loaded scene misses an active arc rotate camera.
 */
export class InvalidLoadedModelSceneError extends Error {
    constructor(msg) {
        super(msg);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, InvalidLoadedModelSceneError.prototype);
    }
}
